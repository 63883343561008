ul li::marker {
    content: "\f0da"; /* FontAwesome Unicode */
    font-family: FontAwesome;
    height: "10px";
  }

  ul {
    --icon-size: .8em;
    --gutter: .5em;
    padding: 0 0 0 var(--icon-size);
  }
  
  ul li {
    padding-left: var(--gutter);
  }