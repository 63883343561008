@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

.underline {
    border-bottom-width: 2px;
}

.logo-style{
font-family: "Dancing Script", cursive;

  font-weight: 400;
  font-style: normal;
  text-transform: lowercase;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(150deg) brightness(100%) contrast(100%);
}

.text-style{
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

.color-black{
    color: #333333 !important;
}

.main_nav{
    background-color: transparent;
}

.scrolled{
    background-color: white;
    transition: all 0.3s ease-out;
    .nav_link,
    a.dropdown-toggle{
        color: #333333 !important;
    }
}

.nav_link,
a.dropdown-toggle {
    text-decoration: none !important;
    font-family: 'Oswald', sans-serif !important;
    font-size: 19px !important;
    padding: 0 10px !important;
    margin: 0px 7px !important;
    border-bottom: 2px solid transparent !important;
    transition: all 0.3s ease-out !important;
    height: 50px !important;
    color: white;
}

a.dropdown-toggle {
    display: flex !important;
    align-items: center !important;
    margin: 0px !important;
    padding: 0px !important;
}

.nav_link:hover,
.nav_link.active:hover,
.nav_link.active.active,
.nav_link.active.active:hover,
.nav_link.active.active:focus {
    color: #333333;
    background-color: #f6ae00;
    border-radius: 3px;
}

.dropdown-menu {
    border-top: none;
}

@media screen and (max-width: 1200px) and (min-width: 992px){
    .nav_link{
        font-size: 14px !important;
    }
}

@media (max-height: 991px) {
    a.dropdown-toggle {
        margin: 0px;
        padding: 0px;
        display: flex;
        align-items: center;
    }

    .dropdown-menu {
        position: absolute !important;
    }
}